import React from "react";
import "./style.scss";
import Table from "react-bootstrap/Table";
import Menu from "../../components/Menu";
import Footer from "../../components/Footer";

const RegulamnetoSorteio = () => {
  return (
    <>
      <Menu />
      <section className="banner-NIQ-sorteio">
        <div className="container">
          <h2>Regulamento sorteios</h2>
          <h3>Participe dos nossos sorteios</h3>
        </div>
      </section>
      
      <section className="container regulamento-sorteio">
        <div className="concorra-tabs">
          <div className="t-regulation">
            <p>
              <strong className="t1-regulation">PROMOÇÃO EBIT 2025 - 1º SEMESTRE</strong>
            </p>
            <p>
              <strong className="t1-regulation">CERTIFICADO DE AUTORIZAÇÃO SPA/MF Nº 04.039220/2024</strong>
            </p>
          </div>

          <div className="regulamento-concorra">
            <p style={{textAlign:'justify'}}>
              <strong> 1. EMPRESA PROMOTORA:</strong>
            </p>
            <p style={{textAlign:'justify'}}>1.1. Razão Social: A.C Nielsen do Brasil Ltda.</p>
            <p style={{textAlign:'justify'}}>1.2. Endereço: Rua Monte Castelo, 55 - Jardim Lambreta – Cotia/SP – CEP: 06710-675.</p>
            <p style={{textAlign:'justify'}}>1.3. CNPJ nº. 33.000.159/0001-65</p>
            <p style={{textAlign:'justify'}}>
              <strong> 2. MODALIDADE DA PROMOÇÃO:</strong>
            </p>
            <p style={{textAlign:'justify'}}>2.1. Assemelhado a sorteio</p>
            <p style={{textAlign:'justify'}}>
              <strong> 3. ÁREA DE ABRANGÊNCIA:</strong>
            </p>
            <p style={{textAlign:'justify'}}>3.1. Território nacional</p>
            <p style={{textAlign:'justify'}}>
              <strong> 4. PERÍODO DA PROMOÇÃO:</strong>
            </p>
            <p style={{textAlign:'justify'}}>4.1. 23/12/2024 a 26/06/2025</p>
            <p style={{textAlign:'justify'}}>
              <strong> 5. PERÍODO DE PARTICIPAÇÃO:</strong>
            </p>
            <p style={{textAlign:'justify'}}>5.1. 23/12/2024 a 22/06/2025</p>
            <p style={{textAlign:'justify'}}>
              <strong> 6. CRITÉRIO DE PARTICIPAÇÃO:</strong>
            </p>
            <p style={{textAlign:'justify'}}>6.1. Poderão participar da promoção “EBIT 2025 - 1º SEMESTRE”, no período de 23/12/2024 a 22/06/2025, todas as pessoas residentes e domiciliadas em território nacional que realizarem as seguintes ações no site<a href="https://www.ebit.com.br"> www.ebit.com.br</a>:</p>
            <p style={{textAlign:'justify'}}>I. Realizar pesquisas comuns, que avaliam as compras das lojas conveniadas, todos que responderem e se associarem a Ebit ou já forem cadastrados na Ebit, ganha 1 (um) cupom/número da sorte para concorrer aos prêmios disponíveis na série A <strong>(00, 01, 02, 03, 05, 06, 07, 08, 10, 11, 12, 13, 15, 16, 17, 18, 20, 21, 22, 23, 25, 26, 27 e 28)</strong>.</p>
            <p style={{textAlign:'justify'}}>II. Realizar pesquisas pontuais ou para estimular atualização de cadastro, o usuário cadastrado que responder e/ou completar, ganhará 1 (um) cupom/número da sorte para concorrer aos prêmios disponíveis na série B <strong>(04, 09, 14, 19, 24 e 29)</strong>.</p>
            <p style={{textAlign:'justify'}}>III. Fornecer voto (online) a pelo menos uma das lojas que concorrerem ao Prêmio Ebit - premiação da Ebit que reconhece as lojas mais queridas do e-commerce pelos consumidores. O usuário cadastrado na Ebit poderá votar dentro do prazo liberado pela empresa promotora para obter 1 (um) cupom/número da sorte para concorrer aos prêmios disponíveis na série B <strong>(04, 09, 14, 19, 24 e 29)</strong>.</p>
            <p style={{textAlign:'justify'}}>6.2. Os cupons/números da sorte deverão ser obtidos até às 23h59 do dia previsto na tabela do item 9.1 deste regulamento, para concorrer ao referido prêmio sorteado 2 (dois) dias depois.</p>
            <p style={{textAlign:'justify'}}>6.3. O “Histórico de cupons” será disponibilizado ao usuário na área logada do consumidor no site, onde é possível visualizar todo o histórico de cupons emitidos pelo usuário.</p>
            <p style={{textAlign:'justify'}}>6.4. Explicativo de distribuição de cupom/número da sorte na promoção:</p>
            <p style={{textAlign:'justify'}}>I. <strong>Série A</strong></p>
            <p style={{textAlign:'justify'}}>Para as pesquisas comuns, que avaliam as compras das lojas conveniadas, todos que responderem e se associarem a Ebit ou já forem cadastrados na Ebit, ganha 1 (um) cupom/número da sorte para concorrer aos prêmios disponíveis na série A.</p>
            <p style={{textAlign:'justify'}}>II. <strong>Série B</strong></p>
            <p style={{textAlign:'justify'}}>Para pesquisas pontuais ou para estimular atualização de cadastro ou votos para o Prêmio Ebit, o usuário cadastrado que responder e/ou completar, ganha 1 (um) cupom/número da sorte para concorrer aos prêmios disponíveis na série B.</p>
            <p style={{textAlign:'justify'}}>6.5. O cupom/número da sorte será composto por 7 (sete) algarismos, sendo os dois primeiros números representando à série, sendo os outros 5 (cinco) números subsequentes representando o elemento sorteável.</p>
            <p style={{textAlign:'justify'}}>6.6. Será considerado o horário de Brasília – DF para todos os períodos descritos neste regulamento.</p>
            <p style={{textAlign:'justify'}}>
              <strong> 7. QUANTIDADE DE SÉRIES:</strong>
            </p>
            <p style={{textAlign:'justify'}}>Serão emitidas 30 (trinta) numeradas de “00” a “29”, sendo 24 (vinte e quatro) séries “A” referente aos sorteios semanais e 6 (seis) séries “B” referente aos sorteios mensais, conforme períodos de participação descritos neste regulamento.</p>
            <p style={{textAlign:'justify'}}>
              <strong> 8. QUANTIDADE DE ELEMENTOS SORTEÁVEIS POR SÉRIE:</strong>
            </p>
            <p style={{textAlign:'justify'}}>100.000 (cem mil)</p>
            <p style={{textAlign:'justify'}}>
              <strong> 9. APURAÇÃO E DESCRIÇÃO DE PRÊMIOS:</strong>
            </p>
            <p style={{textAlign:'justify'}}>9.1. Serão distribuídos 30 (trinta) prêmios, que serão pagos por meio de <strong><u>cartões vale</u></strong> presente, contendo os valores fechados indicados no regulamento. Os sorteados poderão usufruir do cartão para a aquisição dos produtos indicados ou de outros que, na soma, tenham valor equivalente.</p>
              <Table striped bordered hover size="sm">
                <tbody>
                  <tr>
                    <th>Período de Participação</th>
                    <th>Data da Extração </th>
                    <th>Data da Apuração </th>
                    <th>Qntd. de Prêmios</th>
                    <th>Sugestão de prêmio/produto</th>
                    <th>Valor unitário R$</th>
                    <th>Número de Série</th>
                  </tr>
                  <tr>
                    <td><center>23/12/2024 às 00:00 a 29/12/2024 às 23:59</center></td>
                    <td><center>01/01/2025</center></td>
                    <td><center>02/01/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um Smartwatch Amazfit Bip - Xiaomi</center></td>
                    <td><center>370,00</center></td>
                    <td><center>00</center></td>
                  </tr>
                  <tr>
                    <td><center>30/12/2024 às 00:00 a 05/01/2025 às 23:59</center></td>
                    <td><center>08/01/2025</center></td>
                    <td><center>09/01/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Echo Dot 5ª geração - Amazon</center></td>
                    <td><center>390,00</center></td>
                    <td><center>01</center></td>
                  </tr>
                  <tr>
                    <td><center>06/01/2025 às 00:00 a 12/01/2025 às 23:59</center></td>
                    <td><center>15/01/2025</center></td>
                    <td><center>16/01/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Fritadeira Elétrica Sem Óleo – Air Fryer Mondial</center></td>
                    <td><center>350,00</center></td>
                    <td><center>02</center></td>
                  </tr>
                  <tr>
                    <td><center>13/01/2025 às 00:00 a 19/01/2025 às 23:59</center></td>
                    <td><center>22/01/2025</center></td>
                    <td><center>23/01/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Smart TV 32" AOC HD</center></td>
                    <td><center>1.000,00</center></td>
                    <td><center>03</center></td>
                  </tr>
                  <tr>
                    <td><center>23/12/2024 às 00:00 a 19/01/2025 às 23:59</center></td>
                    <td><center>22/01/2025</center></td>
                    <td><center>23/01/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um Smartphone Realme 11 Pro Plus 5G 256GB - 8GB Ram – Xiaomi </center></td>
                    <td><center>2.400,00</center></td>
                    <td><center>04</center></td>
                  </tr>
                  <tr>
                    <td><center>20/01/2025 às 00:00 a 02/02/2025 às 23:59</center></td>
                    <td><center>05/02/2025</center></td>
                    <td><center>06/02/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um Smartwatch Amazfit Bip - Xiaomi</center></td>
                    <td><center>370,00</center></td>
                    <td><center>05</center></td>
                  </tr>
                  <tr>
                    <td><center>03/02/2025 às 00:00 a 09/02/2025 às 23:59</center></td>
                    <td><center>12/02/2025</center></td>
                    <td><center>13/02/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Echo Dot 5ª geração - Amazon</center></td>
                    <td><center>390,00</center></td>
                    <td><center>06</center></td>
                  </tr>
                  <tr>
                    <td><center>10/02/2025 às 00:00 a 16/02/2025 às 23:59</center></td>
                    <td><center>19/02/2025</center></td>
                    <td><center>20/02/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Fritadeira Elétrica Sem Óleo – Air Fryer Mondial</center></td>
                    <td><center>350,00</center></td>
                    <td><center>07</center></td>
                  </tr>
                  <tr>
                    <td><center>17/02/2025 às 00:00 a 23/02/2025 às 23:59</center></td>
                    <td><center>26/02/2025</center></td>
                    <td><center>27/02/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Smart TV 32" AOC HD</center></td>
                    <td><center>1.000,00</center></td>
                    <td><center>08</center></td>
                  </tr>
                  <tr>
                    <td><center>20/01/2025 às 00:00 a 23/02/2025 às 23:59</center></td>
                    <td><center>26/02/2025</center></td>
                    <td><center>27/02/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um Smartphone Realme 11 Pro Plus 5G 256GB - 8GB Ram – Xiaomi </center></td>
                    <td><center>2.400,00</center></td>
                    <td><center>09</center></td>
                  </tr>
                  <tr>
                    <td><center>24/02/2025 às 00:00 a 02/03/2025 às 23:59</center></td>
                    <td><center>05/03/2025</center></td>
                    <td><center>06/03/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um Smartwatch Amazfit Bip - Xiaomi</center></td>
                    <td><center>370,00</center></td>
                    <td><center>10</center></td>
                  </tr>
                  <tr>
                    <td><center>03/03/2025 às 00:00 a 09/03/2025 às 23:59</center></td>
                    <td><center>12/03/2025</center></td>
                    <td><center>13/03/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Echo Dot 5ª geração - Amazon</center></td>
                    <td><center>390,00</center></td>
                    <td><center>11</center></td>
                  </tr>
                  <tr>
                    <td><center>10/03/2025 às 00:00 a 16/03/2025 às 23:59</center></td>
                    <td><center>19/03/2025</center></td>
                    <td><center>20/03/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Fritadeira Elétrica Sem Óleo – Air Fryer Mondial</center></td>
                    <td><center>350,00</center></td>
                    <td><center>12</center></td>
                  </tr>
                  <tr>
                    <td><center>17/03/2025 às 00:00 a 23/03/2025 às 23:59</center></td>
                    <td><center>26/03/2025</center></td>
                    <td><center>27/03/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Smart TV 32" AOC HD</center></td>
                    <td><center>1.000,00</center></td>
                    <td><center>13</center></td>
                  </tr>
                  <tr>
                    <td><center>24/02/2025 às 00:00 a 23/03/2025 às 23:59</center></td>
                    <td><center>26/03/2025</center></td>
                    <td><center>27/03/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um Smartphone Realme 11 Pro Plus 5G 256GB - 8GB Ram – Xiaomi </center></td>
                    <td><center>2.400,00</center></td>
                    <td><center>14</center></td>
                  </tr>
                  <tr>
                    <td><center>24/03/2025 às 00:00 a 30/03/2025 às 23:59</center></td>
                    <td><center>02/04/2025</center></td>
                    <td><center>03/04/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um Smartwatch Amazfit Bip - Xiaomi</center></td>
                    <td><center>370,00</center></td>
                    <td><center>15</center></td>
                  </tr>
                  <tr>
                    <td><center>31/03/2025 às 00:00 a 06/04/2025 às 23:59</center></td>
                    <td><center>09/04/2025</center></td>
                    <td><center>10/04/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Echo Dot 5ª geração - Amazon</center></td>
                    <td><center>390,00</center></td>
                    <td><center>16</center></td>
                  </tr>
                  <tr>
                    <td><center>07/04/2025 às 00:00 a 13/04/2025 às 23:59</center></td>
                    <td><center>16/04/2025</center></td>
                    <td><center>17/04/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Fritadeira Elétrica Sem Óleo – Air Fryer Mondial</center></td>
                    <td><center>350,00</center></td>
                    <td><center>17</center></td>
                  </tr>
                  <tr>
                    <td><center>14/04/2025 às 00:00 a 20/04/2025 às 23:59</center></td>
                    <td><center>23/04/2025</center></td>
                    <td><center>24/04/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Smart TV 32" AOC HD</center></td>
                    <td><center>1.000,00</center></td>
                    <td><center>18</center></td>
                  </tr>
                  <tr>
                    <td><center>24/03/2025 às 00:00 a 20/04/2025 às 23:59</center></td>
                    <td><center>23/04/2025</center></td>
                    <td><center>24/04/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um Smartphone Realme 11 Pro Plus 5G 256GB - 8GB Ram – Xiaomi </center></td>
                    <td><center>2.400,00</center></td>
                    <td><center>19</center></td>
                  </tr>
                  <tr>
                    <td><center>21/04/2025 às 00:00 a 27/04/2025 às 23:59</center></td>
                    <td><center>30/04/2025</center></td>
                    <td><center>01/05/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Smart TV 32" AOC HD</center></td>
                    <td><center>1.000,00</center></td>
                    <td><center>20</center></td>
                  </tr>
                  <tr>
                    <td><center>28/04/2025 às 00:00 a 04/05/2025 às 23:59</center></td>
                    <td><center>07/05/2025</center></td>
                    <td><center>08/05/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Echo Dot 5ª geração - Amazon</center></td>
                    <td><center>390,00</center></td>
                    <td><center>21</center></td>
                  </tr>
                  <tr>
                    <td><center>05/05/2025 às 00:00 a 11/05/2025 às 23:59</center></td>
                    <td><center>14/05/2025</center></td>
                    <td><center>15/05/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Fritadeira Elétrica Sem Óleo – Air Fryer Mondial</center></td>
                    <td><center>350,00</center></td>
                    <td><center>22</center></td>
                  </tr>
                  <tr>
                    <td><center>12/05/2025 às 00:00 a 18/05/2025 às 23:59</center></td>
                    <td><center>21/05/2025</center></td>
                    <td><center>22/05/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um Smartwatch Amazfit Bip - Xiaomi</center></td>
                    <td><center>370,00</center></td>
                    <td><center>23</center></td>
                  </tr>
                  <tr>
                    <td><center>21/04/2025 às 00:00 a 18/05/2025 às 23:59</center></td>
                    <td><center>21/05/2025</center></td>
                    <td><center>22/05/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um iPhone SE Apple 64gb</center></td>
                    <td><center>2.400,00</center></td>
                    <td><center>24</center></td>
                  </tr>
                  <tr>
                    <td><center>19/05/2025 às 00:00 a 01/06/2025 às 23:59</center></td>
                    <td><center>04/06/2025</center></td>
                    <td><center>05/06/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Smart TV 32" AOC HD</center></td>
                    <td><center>1.000,00</center></td>
                    <td><center>25</center></td>
                  </tr>
                  <tr>
                    <td><center>02/06/2025 às 00:00 a 08/06/2025 às 23:59</center></td>
                    <td><center>11/06/2025</center></td>
                    <td><center>12/06/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Echo Dot 5ª geração - Amazon</center></td>
                    <td><center>390,00</center></td>
                    <td><center>26</center></td>
                  </tr>
                  <tr>
                    <td><center>09/06/2025 às 00:00 a 15/06/2025 às 23:59</center></td>
                    <td><center>18/06/2025</center></td>
                    <td><center>19/06/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir uma Fritadeira Elétrica Sem Óleo – Air Fryer Mondial</center></td>
                    <td><center>350,00</center></td>
                    <td><center>27</center></td>
                  </tr>
                  <tr>
                    <td><center>16/06/2025 às 00:00 a 22/06/2025 às 23:59</center></td>
                    <td><center>25/06/2025</center></td>
                    <td><center>26/06/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um Smartwatch Amazfit Bip - Xiaomi</center></td>
                    <td><center>370,00</center></td>
                    <td><center>28</center></td>
                  </tr>
                  <tr>
                    <td><center>19/05/2025 às 00:00 a 22/06/2025 às 23:59</center></td>
                    <td><center>25/06/2025</center></td>
                    <td><center>26/06/2025</center></td>
                    <td><center>1</center></td>
                    <td><center>1 (um) Cartão Vale Presente dando oportunidade de adquirir um iPhone SE Apple 64gb</center></td>
                    <td><center>2.400,00</center></td>
                    <td><center>29</center></td>
                  </tr>
                </tbody>
              </Table>
            <p style={{textAlign:'justify'}}>9.2. Cada apuração ocorrerá na sede da empresa promotora, localizada na Rua Monte Castelo, 55 - Jardim Lambreta – Cotia/SP – CEP: 06710-675, sempre às 11h00, conforme a data descrita na tabela acima.</p>
            <p style={{textAlign:'justify'}}>
              <strong> 10. PREMIAÇÃO TOTAL:</strong>
            </p>
              <Table striped bordered hover size="sm">
                <tbody>
                  <tr>
                    <th>Quantidade Total de Prêmios</th>
                    <th>Valor total da Promoção</th>
                  </tr>
                  <tr>
                    <td><center>30</center></td>
                    <td><center>R$ 27.060,00</center></td>
                  </tr>
                </tbody>
              </Table>
            <p style={{textAlign:'justify'}}>
              <strong> 11. FORMA DE APURAÇÃO:</strong>
            </p>
            <p style={{textAlign:'justify'}}>11.1. O número da sorte será composto de 7 (sete) algarismos, sendo o primeiro e segundo correspondente ao número de série e período de participação e os 5 (cinco) algarismos subsequentes ao número de ordem.</p>
            <p style={{textAlign:'justify'}}>11.2. Serão emitidas 30 (trinta) séries em toda promoção, cada uma contendo 100.000 (cem mil números) de números da sorte, totalizando o universo de “00.000” (zero) a “99.999” (noventa e nove mil, novecentos e noventa e nove), de acordo com cada período de participação exposto na tabela do item 9.1 deste regulamento.</p>
            <p style={{textAlign:'justify'}}>11.3. O sistema de gerenciamento da promoção será capaz de garantir a distribuição concomitante de Números da Sorte, de forma equitativa e aleatória, sendo o contemplado definido com base no resultado da extração da Lotomania.</p>
            <p style={{textAlign:'justify'}}>11.4. Caso a quantidade de Números da Sorte atribuídos supere a estimativa de uso das séries previstas, a distribuição de números será considerada encerrada.</p>
            <p style={{textAlign:'justify'}}>11.5. Caso a Extração da Lotomania não venha a ocorrer em na data prevista, por qualquer motivo, será considerada para efeito de apuração do resultado desta promoção, a data da Extração da Lotomania subsequente.</p>
            <p style={{textAlign:'justify'}}>11.6. Para a obtenção do número de ordem contemplado em cada sorteio/série, serão utilizados números formados pelas das unidades simples do 1º ao 5º número, em ordem de extração da Lotomania, conforme exemplo I abaixo:</p>
            <p style={{textAlign:'justify'}}>Exemplo I: 9<strong style={{ color: "red" }}>2</strong>, 5<strong style={{ color: "red" }}>5</strong>, 7<strong style={{ color: "red" }}>2</strong>, 6<strong style={{ color: "red" }}>2</strong>, 2<strong style={{ color: "red" }}>9</strong>, 96, 39, 31, 51, 12, 97, 09, 35, 73, 70, 58, 46, 25, 45 e 26</p>
            <p style={{textAlign:'justify'}}>Exemplo de número de ordem contemplado dentro da série participante: <strong style={{ color: "red" }}>25229</strong></p>
            <p style={{textAlign:'justify'}}>11.7. Na eventualidade do número de ordem sorteado não ter sido atribuído dentro da série participante no referido sorteio, o prêmio caberá ao portador do número imediatamente superior ou, na falta deste, ao imediatamente inferior e assim sucessivamente.</p>
            <p style={{textAlign:'justify'}}>
              <strong> 12. CRITÉRIOS DE DESCLASSIFICAÇÃO:</strong>
            </p>
            <p style={{textAlign:'justify'}}>12.1. Os participantes serão excluídos automaticamente da promoção em caso de fraude comprovada, de não preenchimento dos requisitos previamente determinados e/ou em decorrência da prestação de informações comprovadamente incorretas ou equivocadas, de acordo com as regras do regulamento da promoção.</p>
            <p style={{textAlign:'justify'}}>12.2. O contemplado porventura desclassificado da promoção, incluindo os impedidos de participar (pessoas jurídicas; sócios, diretores, administradores da empresa promotora), não terá direito ao recebimento do prêmio e a empresa promotora aplicará a regra de aproximação descrita no item 11.7 deste regulamento, para localização de um novo contemplado deste prêmio.</p>
            <p style={{textAlign:'justify'}}>
              <strong> 13. FORMA DE DIVULGAÇÃO DO RESULTADO:</strong>
            </p>
            <p style={{textAlign:'justify'}}>13.1. O nome e número da sorte dos contemplados serão divulgados no site<a href="https://www.ebit.com.br"> www.ebit.com.br</a>, no prazo de até 7 (sete) dias úteis respeitando o calendário oficial da cidade de São Paulo/SP, a contar da data da respectiva apuração. Está divulgação permanecerá por 30 (trinta) dias ou até o efetivo contato com o referido contemplado.</p>
            <p style={{textAlign:'justify'}}>
              <strong> 14. ENTREGA DOS PRÊMIOS:</strong>
            </p>
            <p style={{textAlign:'justify'}}>14.1. A empresa promotora irá comunicar o potencial contemplado sobre a contemplação e premiação no prazo de até 10 (dez) dias úteis, a contar da data do respectivo sorteio.</p>
            <p style={{textAlign:'justify'}}>14.1.1. Após o efetivo contato com o potencial contemplado, o mesmo terá o prazo de até 3 (três) dias para apresentar e entregar a documentação exigida, contados da data do primeiro contato da empresa promotora, sob pena de desclassificação, aplicando-se a regra descrita no item 11.7 deste regulamento.</p>
            <p style={{textAlign:'justify'}}>14.2. O prêmio a ser distribuídos destina-se ao usuário cadastrado no site<a href="https://www.ebit.com.br"> www.ebit.com.br</a>.</p>
            <p style={{textAlign:'justify'}}>14.3. O prêmio é pessoal e intransferível e será entregue no domicilio do contemplado cadastrado no site<a href="https://www.ebit.com.br"> www.ebit.com.br</a>, no prazo de até 30 (trinta) dias contados da data do sorteio, de acordo com o Artigo 5º do Decreto 70.951/72.</p>
            <p style={{textAlign:'justify'}}>14.4. Os prêmios distribuídos deverão ser livres e desembaraçados de qualquer ônus para os contemplados.</p>
            <p style={{textAlign:'justify'}}>14.5. Na eventualidade do participante vir a falecer, o prêmio será entregue ao respectivo espólio, na pessoa de seu inventariante.</p>
            <p style={{textAlign:'justify'}}>14.6. Uma vez identificada à prática de ato tipificado como ilícito penal, o cliente participante contemplado responderá pelo crime de falsificação de documentos ou uso de documento falso, sem prejuízo de responder civilmente por seus atos.</p>
            <p style={{textAlign:'justify'}}>14.7. Caberá, igualmente, ao cliente participante contemplado, na ocasião do recebimento do prêmio, a assinatura do Recibo de Entrega do Prêmio, o qual deverá ser devolvido à empresa promotora como comprovante de regularidade desta campanha promocional.</p>
            <p style={{textAlign:'justify'}}>
              <strong> 15. DISPOSIÇÕES GERAIS:</strong>
            </p>
            <p style={{textAlign:'justify'}}>15.1. De acordo com o Art. 15 – Parágrafo 1 do Dec. 70.951/72, a empresa comprovará a propriedade dos prêmios em até 08 (oito) dias antes do sorteio, na qual o prêmio será exibido em imagens meramente ilustrativas no site<a href="https://www.ebit.com.br"> www.ebit.com.br</a> e nas peças promocionais da campanha.</p>
            <p style={{textAlign:'justify'}}>15.2. Conforme o disposto no art. 70, inciso 1º, “b”, da Lei nº. 11.196, de 21/05/05, a empresa promotora recolherá 20% de IRF sobre o valor dos prêmios, até o 3º dia útil subsequente ao decêndio de ocorrência dos fatos geradores, através de DARF, recolhida na rede bancária, com o código 0916.</p>
            <p style={{textAlign:'justify'}}>15.3. Quando o prêmio sorteado, ganho em concurso ou conferido mediante vale-brinde, não for reclamado no prazo de 180 (cento e oitenta) dias, contados, respectivamente, da data do sorteio, da apuração do resultado do concurso ou do término do prazo da promoção, caducará o direito do respectivo titular e o valor correspondente será recolhido, pela empresa autorizada, ao Tesouro Nacional, como renda da União, no prazo de 10 (dez) dias.</p>
            <p style={{textAlign:'justify'}}>15.4. O cliente participante contemplado, desde já, autoriza o uso, pela empresa Promotora pelo período de 12 (doze) meses, a contar da divulgação do resultado da presente Promoção, de suas imagens, sons de vozes, performances e nomes, em filmes publicitários veiculados em mídia eletrônica, Internet, fotos, cartazetes, anúncios em jornais, revistas e outros e em qualquer outra forma de mídia impressa ou eletrônica, a serem divulgados no Território Nacional, referentes à divulgação da conquista dos prêmios, sem qualquer ônus à empresa promotora.</p>
            <p style={{textAlign:'justify'}}>15.5. O Regulamento da promoção será disponibilizado no site<a href="https://www.ebit.com.br"> www.ebit.com.br</a>.</p>
            <p style={{textAlign:'justify'}}>15.6. A participação na presente Promoção caracteriza, por si, a aceitação por parte dos clientes participantes de todos os termos e condições estabelecidos neste regulamento.</p>
            <p style={{textAlign:'justify'}}>15.7. A empresa encaminhará a SPA/MF à lista de participantes, contendo nomes e números da sorte distribuídos, após o término de cada período de participação e antes da extração da lotomania.</p>
            <p style={{textAlign:'justify'}}>15.8. O número do Certificado de Autorização constará de forma clara e precisa no site<a href="https://www.ebit.com.br"> www.ebit.com.br</a>. Para os demais materiais de divulgação, a empresa promotora solicitou dispensa da aposição, fazendo constar, apenas, a indicação de consulta do número de autorização SPA/MF no referido site.</p>
            <p style={{textAlign:'justify'}}>15.9. De acordo com o art. 11 da Portaria 41/2008 do MF, é deferido à empresa promotora a formação de cadastro e/ou banco de dados com as informações coletadas na presente promoção, sendo vedada à comercialização ou cessão, ainda que a título gratuito, desses dados.</p>
            <p style={{textAlign:'justify'}}>15.10. As dúvidas e controvérsias originadas de reclamações dos participantes das promoções autorizadas deverão ser preliminarmente, dirimidas exclusivamente pela promotora e, posteriormente, submetidas à SPA/MF, quando o participante não optar pela reclamação direta aos órgãos públicos integrantes do Sistema Nacional de Defesa do Consumidor.</p>
            <p style={{textAlign:'justify'}}>15.11. Fica, desde já, eleito o foro central da Comarca do participante para solução de quaisquer questões referentes ao Regulamento da presente promoção.</p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default RegulamnetoSorteio;
