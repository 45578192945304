import React, { useEffect, useState, useRef } from "react";
import useDebounce from "../../../utils/useDebounce";
import "./styles.scss";
import companyServices from "../../../services/companyServices";
import { Nav } from "react-bootstrap";
import CompanyBox from "../../../Company/components/CompanyBox";

import DNIQ from "../../../assets/imagens/medalhas/newDiamante.png";
import ONIQ from "../../../assets/imagens/medalhas/newOuro.png";
import PNIQ from "../../../assets/imagens/medalhas/newPrata.png";
import BNIQ from "../../../assets/imagens/medalhas/newBronze.png";

const CompanyList = () => {
  const imageRef = useRef();
  const [companies, setCompanies] = useState([]);
  const [searchCompany, setSearchCompany] = useState({});
  const [search, setSearch] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);

  const SEARCH_DEBOUNCE_DELAY = 900;
  const debouncedSearchTerm = useDebounce(search, SEARCH_DEBOUNCE_DELAY);

  /** Delay to search store */
  useEffect(
    () => {
      if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
        if (search) {
          getSearchCompany(search).then((result) => {
            if (result) {
              setCompanies(result.Data);
            }
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm]
  );

  const getCompanies = async (medal) => {
    const params = {
      active: true,
      shuffle: true,
      medal,
      order: null,
    };

    const companies = await companyServices.getAll(params);
    setCompanies([]);
    setCompanies(companies.Data);
    setFirstLoad(false);
  };

  const getSearchCompany = async (name) => {
    const params = {
      name,
    };
    return companyServices.getByName(params);
  };

  useEffect(() => {
    getCompanies("diamante");
  }, []);

  const handleClick = (medal) => {
    setSearch("");
    getCompanies(medal);
  };

  return (
    <>
      <div className="company-reputation">
        {/* <h2 className="company-reputation__title">Reputação das lojas</h2>
        <p className="company-reputation__sub-title">
          Confira as avaliações dos usuários Ebit.
        </p> */}
      </div>

      <div className="store-search container">
        <input
          type="search"
          placeholder="Busque o e-commerce que deseja saber a reputação!"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />        
      </div>

      <div className="new-nav-NIQ container">
          <div className="btn-navIQ" onClick={() => handleClick("diamante")}>
            <img src={DNIQ} alt="" />
            <p>Lojas diamante</p>
          </div>

          <div className="btn-navIQ" onClick={() => handleClick("ouro")}>
            <img src={ONIQ} alt="" />
            <p>Lojas ouro</p>
          </div>

          <div className="btn-navIQ" onClick={() => handleClick("prata")}>
            <img src={PNIQ} alt="" />
            <p>Lojas prata</p>
          </div>

          <div className="btn-navIQ" onClick={() => handleClick("bronze")}>
            <img src={BNIQ} alt="" />
            <p>Lojas bronze</p>
          </div>

          {/* <div className="btn-navIQ" onClick={() => handleClick("diamante")}>
            <img src={Diamante} alt="" />
            <p>Lojas Diamante</p>
          </div> */}
        </div>

      <div className="teste-bg">
        

        <div className="companies container">
          {companies.length > 0 ? (
            (companies || []).map((company) => <CompanyBox company={company} />)
          ) : firstLoad ? (
            <div className="warning-company">
              <h1>Carregando...</h1>
            </div>
          ) : (
            <div className="warning-company">
              <h1>
                Desculpe, não conseguimos encontrar a loja que está
                procurando...
              </h1>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CompanyList;
